import React, { Component } from 'react';
import css from './SectionNews.module.css';
import { lazyLoadWithDimensions } from '../../../util/contextHelpers';
import AliceCarousel from 'react-alice-carousel';
import moment from 'moment';

// Copied directly from
// `/node_modules/react-alice-carousel/lib/alice-carousel.css`. The
// copied file is left unedited, and all the overrides are defined in
// the component CSS file below.
import '../../../styles/react-alice-carousel.css';
import { FormattedMessage } from "../../../util/reactIntl";
import {
  AvatarSemiLarge, NamedLink,
  ResponsiveImage,
  ReviewRating
} from "../../../components";
import config from "../../../config";
import { newsData } from '../../../util/newsData';
import { formatDateIntoPartials, formatDateWithProximity } from "../../../util/dates";
import { shuffle } from "lodash";

const SectionNews = (props) => {

  const news = shuffle(newsData);
  const items = () => {

    const newsLogo = news.filter(item => !!item.logo);

    return newsLogo.map(n => {
      return (
        <NamedLink className={css.newsCarouselItem} name={n.pageLink}>
          <img className={css.newsCarouselImg} src={n.logo} alt={n.description} />
        </NamedLink>
      )
    }
    );
  };


  return <div className={css.newsCarouselHolder}>
    <h2 className={css.rootTitle}>
      <FormattedMessage id="SectionNews.sectionTitle" />
    </h2>
    <AliceCarousel
      mouseTrackingEnabled
      items={items()}
      disableDotsControls={true}
      // infinite={true}
      responsive={{
        0: {
          items: 1,
        },
        550: {
          items: 2,
        },
        1024: {
          items: 4,
        },
        1280: {
          items: 5,
        }
      }}
    />
    <div className={css.buttonHolder}>
      <NamedLink name="NewsListPage" className={css.primaryButton}>
        <FormattedMessage id="SectionNews.sectionButton" />
      </NamedLink>
    </div>
  </div>;
};

export default SectionNews;
