import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import css from './SectionPossibilities.module.css';
import { Button, NamedLink } from '../../../components';

import item1Img from "./item1.svg"
import item2Img from "./item2.svg"
import item3Img from "./item3.svg"
import classNames from "classnames";

const SectionPossibilities = props => {

  const { currentUser, handleGetStarted } = props


  return (
    <div>

      <div className={css.rootTitleHolder}>
        <h2 className={css.rootTitle}>
          <FormattedMessage id="SectionPossibilities.title" />
        </h2>
      </div>

      <p className={css.rootDescription}>
        <FormattedMessage id="SectionPossibilities.description" />
      </p>

      <div className={css.infoList}>

        <div className={css.infoItem}>
          <div className={css.infoImage}>
            <img src={item1Img} alt='Post a job' className={css.image} />
          </div>
          <div className={css.infoText}>
            <h3 className={css.infoTextTitle}>
              <FormattedMessage id="SectionPossibilities.item1" />
            </h3>
            <p className={css.infoTextDescription}>
              <FormattedMessage id="SectionPossibilities.itemDescription1" />
            </p>
          </div>
        </div>

        <div className={css.infoItem}>
          <div className={css.infoImage}>
            <img src={item2Img} className={css.image} alt='Get Matched with Teachers' />
          </div>
          <div className={css.infoText}>
            <h3 className={css.infoTextTitle}>
              <FormattedMessage id="SectionPossibilities.item2" />
            </h3>
            <p className={css.infoTextDescription}>
              <FormattedMessage id="SectionPossibilities.itemDescription2" />
            </p>
          </div>
        </div>

        <div className={classNames(css.infoItem)}>
          <div className={css.infoImage}>
            <img src={item3Img} className={css.image} alt='Fill Vacancies Immediately' />
          </div>
          <div className={css.infoText}>
            <h3 className={css.infoTextTitle}>
              <FormattedMessage id="SectionPossibilities.item3" />
            </h3>
            <p className={css.infoTextDescription}>
              <FormattedMessage id="SectionPossibilities.itemDescription3" />
            </p>
          </div>
        </div>
      </div>

      {!currentUser && (
        <Button className={css.primaryButton} onClick={handleGetStarted}>
          <FormattedMessage id="SectionPossibilities.button" />
        </Button>
      )}


    </div>
  );
};

export default SectionPossibilities;
