import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from '../../LandingPage.module.css';

const IconCard = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="89"
      fill="none"
      viewBox="0 0 60 89"
    >
      <g clipPath="url(#clip0_1409_7690)">
        <path
          fill="#454545"
          d="M39.687 89l-31.402-.023c-3.775 0-6.836-2.95-6.836-6.587l.015-75.813c0-3.64 3.066-6.59 6.847-6.587l31.404.018c3.775 0 6.836 2.95 6.836 6.586l-.015 75.811c-.003 3.65-3.073 6.597-6.849 6.595z"
        ></path>
        <path
          fill="#fff"
          d="M39.69 2.698h-6.097a1.462 1.462 0 00-1.043.435 1.49 1.49 0 00-.434 1.053V5.25a1.505 1.505 0 01-.435 1.054 1.475 1.475 0 01-1.045.434H18.768a1.462 1.462 0 01-1.043-.435 1.491 1.491 0 01-.434-1.053V4.184a1.505 1.505 0 00-.435-1.054 1.475 1.475 0 00-1.045-.434H8.34c-2.646 0-4.783 2.065-4.783 4.613l-.015 74.008c0 2.543 2.142 4.613 4.782 4.613l31.342.018c2.648 0 4.782-2.062 4.795-4.61l.015-74.008c-.005-2.564-2.15-4.63-4.787-4.632z"
        ></path>
        <path
          fill="#454545"
          d="M22.956 21.144a1.78 1.78 0 001.165-.554 1.816 1.816 0 00.06-2.427 1.782 1.782 0 00-1.137-.612 1.892 1.892 0 00-.092-2.194 1.84 1.84 0 00-2.09-.604 2.254 2.254 0 00-.533-1.012 2.203 2.203 0 00-3.104-.16 2.495 2.495 0 00-3.038.524 1.768 1.768 0 00-1.067.355 1.798 1.798 0 00-.644.93c-.11.372-.097.77.036 1.134.133.364.379.675.7.887"
        ></path>
        <path
          fill="#EB9973"
          d="M23.26 21.343a7.658 7.658 0 00-3.308-4.474 7.52 7.52 0 00-5.434-1.02l-.385.074c-4.112 1.048-5.537 5.16-4.384 9.288l2.88 14.98 8.474 6.612 2.192-5.516-.863-4.985s3.881-1.399 3.169-5.448c-.335-1.96-1.367-5.93-2.341-9.511z"
        ></path>
        <path
          fill="#EB9973"
          d="M22.475 36.284a9.786 9.786 0 01-5.538-.542s1.735 2.76 5.656 1.628l-.118-1.086z"
        ></path>
        <path
          fill="#454545"
          d="M22.9 25.218a.582.582 0 01-.44.69.552.552 0 01-.605-.842.582.582 0 01.359-.255.552.552 0 01.687.407zM22.772 23.487c-.055.086-.548-.153-1.162-.034-.615.12-1.007.51-1.088.456-.04-.028 0-.178.176-.361a1.59 1.59 0 01.854-.456c.319-.064.65-.022.944.12.216.11.306.234.276.275zM17.33 26.414c.03.15 0 .306-.083.434a.581.581 0 01-.36.252.547.547 0 01-.59-.205.565.565 0 01-.094-.201.59.59 0 01.082-.435.58.58 0 01.358-.252.551.551 0 01.593.205c.045.06.077.129.095.202zM16.752 24.763c-.056.09-.549-.152-1.163-.033-.614.12-1.007.509-1.087.456-.038-.029 0-.178.176-.36.23-.232.525-.388.845-.447.32-.062.65-.02.944.12.224.099.315.224.285.264zM19.85 28.94c0-.036.36-.176.964-.377.153-.046.297-.104.302-.219a.83.83 0 00-.194-.45l-.69-1.116c-.969-1.595-1.701-2.92-1.638-2.958.063-.038.9 1.223 1.87 2.818l.672 1.126a.948.948 0 01.199.608.413.413 0 01-.214.282c-.08.04-.165.068-.252.087-.624.155-1.01.231-1.02.198zM16.53 22.614c-.05.173-.609.105-1.258.206-.65.102-1.178.318-1.259.166-.04-.074.048-.237.252-.407.272-.211.594-.347.934-.394.334-.053.676-.015.992.109.249.107.372.239.34.32zM22.43 21.274c-.103.165-.636.117-1.258.226-.622.11-1.108.313-1.259.189-.065-.064-.012-.22.189-.397.28-.226.614-.373.97-.425a1.94 1.94 0 011.046.092c.26.114.358.234.312.315z"
        ></path>
        <path
          fill="#EB9973"
          d="M11.143 29.018a1.12 1.12 0 00-.51-.713 1.099 1.099 0 00-.862-.119c-.732.217-1.483.804-1.138 2.35.607 2.745 3.21 1.562 3.197 1.483-.013-.079-.423-1.854-.687-3.001z"
        ></path>
        <path
          fill="#EB9973"
          d="M11.01 31.119c-.013 0-.041.046-.111.099a.477.477 0 01-.353.089c-.312-.043-.654-.448-.778-.938a1.603 1.603 0 01-.017-.715.566.566 0 01.274-.448.25.25 0 01.307.067c.053.068.048.127.066.13.017.002.047-.06 0-.169a.314.314 0 00-.146-.147.356.356 0 00-.277 0 .68.68 0 00-.4.536 1.724 1.724 0 000 .801c.14.55.536 1.018.954 1.03a.517.517 0 00.417-.17c.073-.096.073-.16.063-.165z"
        ></path>
        <path
          fill="#454545"
          d="M19.904 16.035a1.022 1.022 0 00-.133-.178 1.783 1.783 0 00-1.51-.521 6.762 6.762 0 00-1.035-.224 2.398 2.398 0 00-3.324-.364 2.444 2.444 0 00-.748.97c-.111.037-.22.078-.328.121a2.45 2.45 0 00-3.04.747 2.51 2.51 0 00-.456 1.033 1.779 1.779 0 00-1.013.226c-.309.175-.56.437-.72.755a1.83 1.83 0 00.223 1.995c-.438.379-.755.954-.67 1.526.086.572.68.974 1.181.811.154 1.343.587 2.874.914 4.235.231.958 1.558 1.3 1.762 1.94 1.057 3.268 1.792 1.208.9-5.809.059-.068.12-.14.177-.216.085-.164.158-.334.219-.508.116-.206.403-.204.637-.224a1.77 1.77 0 001.068-.525c.286-.294.463-.678.502-1.088.252.255.733.163 1.007-.096.24-.286.44-.604.592-.946.188-.326.478-.639.848-.657.206.03.414.036.622.018.352-.099.448-.56.433-.928a1.751 1.751 0 01.115-.92c.197-.151.42-.264.657-.334.139 0 .282 0 .418-.015.065-.004.13-.012.194-.026.09.013.179.03.267.054 1.319.358 2.849 2.686 2.849 2.686-.191-1.628-1.319-2.798-2.608-3.538z"
        ></path>
        <path
          fill="#455A64"
          d="M7.504 20.796s0-.015.018-.046c.017-.03.032-.071.065-.125.1-.151.223-.285.367-.394a1.78 1.78 0 01.778-.333c.373-.066.757 0 1.088.188l-.07.038a2.235 2.235 0 01.87-1.6c.275-.22.588-.386.924-.49a3.323 3.323 0 011.092-.13c.69.043 1.368.215 1.996.509l-.075.04a2.508 2.508 0 01.986-1.543 2.381 2.381 0 012.298-.255c.078.033.154.07.227.112.047.03.073.049.07.051-.002.003-.106-.053-.31-.13a2.471 2.471 0 00-.913-.15 2.31 2.31 0 00-1.319.448 2.43 2.43 0 00-.934 1.488l-.012.069-.063-.028a5.52 5.52 0 00-1.956-.51 3.291 3.291 0 00-1.055.126 2.636 2.636 0 00-.888.468 2.13 2.13 0 00-.848 1.525v.077l-.066-.038a1.55 1.55 0 00-1.03-.196 1.723 1.723 0 00-1.24.829z"
        ></path>
        <path
          fill="#455A64"
          d="M18.134 17.017a.734.734 0 01-.027.117 2.252 2.252 0 00-.043.338c0 .15 0 .333-.018.55.006.25-.04.499-.138.73a.58.58 0 01-.345.281 2.08 2.08 0 01-.47.059.928.928 0 00-.459.122 1.267 1.267 0 00-.34.39c-.095.154-.18.325-.274.508a2.036 2.036 0 01-.327.508c-.158.152-.351.261-.561.318a.975.975 0 01-.335.04.47.47 0 01-.33-.142l.096-.035a1.556 1.556 0 01-.353.954 1.944 1.944 0 01-.88.592 2.58 2.58 0 01-.436.1.917.917 0 00-.398.076.576.576 0 00-.252.29c-.063.128-.136.25-.218.366a2.182 2.182 0 01-1.282.946c-.484.07-.977-.04-1.386-.31a2.087 2.087 0 01-.796-.936l.053.02a1.084 1.084 0 01-.929-.043 1.041 1.041 0 01-.47-.53 1.042 1.042 0 01-.066-.554v-.038c-.015.198.018.396.096.578a.993.993 0 00.889.598c.15.005.3-.023.437-.082l.038-.015.015.035c.165.367.434.676.773.888.39.252.858.353 1.316.285.5-.137.932-.457 1.21-.898.08-.111.151-.229.212-.351a.705.705 0 01.297-.34.612.612 0 01.222-.072l.214-.018c.14-.02.278-.051.412-.096.323-.104.61-.295.831-.555.206-.248.323-.56.33-.885v-.132l.09.096a.565.565 0 00.554.074c.194-.052.37-.151.516-.29.135-.147.245-.316.327-.498.096-.173.184-.344.285-.509.093-.163.22-.304.37-.414a.998.998 0 01.503-.13 2.1 2.1 0 00.453-.051.502.502 0 00.305-.24c.098-.219.148-.457.146-.698.012-.214.02-.4.035-.55.01-.115.032-.23.063-.34a.486.486 0 01.045-.107z"
        ></path>
        <path
          fill="#EB9973"
          d="M18.14 30.392c-.114.763.21 1.492.92 1.754a1.089 1.089 0 001.173-.352c.092-.113.162-.243.204-.383a2.04 2.04 0 00.018-.953c-.652-.038-1.659-.028-2.316-.066z"
        ></path>
        <path
          fill="#454545"
          d="M20.308 31.72s0 .02-.028.053a.695.695 0 01-.11.132c-.155.149-.35.247-.562.282a1.343 1.343 0 01-.916-.188 1.43 1.43 0 01-.616-.97 1.618 1.618 0 01-.016-.31c0-.108.029-.215.081-.31a.27.27 0 01.138-.109.435.435 0 01.157-.02h.266c.345.017.655.05.914.078.516.056.838.072.838.092-.28.033-.565.033-.846 0-.251-.015-.569-.04-.91-.05h-.253c-.088 0-.15.012-.188.073a.593.593 0 00-.056.254 1.354 1.354 0 00.567 1.17c.248.16.543.229.835.196.2-.023.388-.102.544-.229.106-.086.161-.155.161-.145z"
        ></path>
        <path
          fill="#EE4D68"
          d="M35.508 22.942s3.189-.401 7.981-4.255c4.793-3.853 7.39-9.328 7.924-9.247.533.082 2.987-.8 6.544 9.446 3.556 10.247 1.578 12.36.692 12.256-.886-.105-6.766-2.378-10.571-2.302-3.806.076-8.843 1.597-8.843 1.597l-3.727-7.495z"
        ></path>
        <path
          fill="#455A64"
          d="M29.773 30.592l1.704-.366-.924-2.266-1.44.728.66 1.904z"
        ></path>
        <path
          fill="#454545"
          d="M29.973 27.9l.629 1.912.393.961a2.671 2.671 0 001.366 1.438 2.63 2.63 0 001.971.088l6.914-1.966-2.97-8.845-6.871 2.927a2.67 2.67 0 00-1.425 1.445 2.709 2.709 0 00-.007 2.04z"
        ></path>
        <path
          fill="#454545"
          d="M32.453 30.839l2.316 4.148 1.057-.343 7.231 5.452 1.795-3.947s-4.209-1.625-5.034-3.082c-.826-1.457.045-1.831.045-1.831l-2.036-2.152-5.374 1.755zM57.577 19.038c1.88 5.547 2.429 10.382 1.223 10.799-1.206.417-3.71-3.738-5.593-9.285-1.882-5.547-2.428-10.382-1.223-10.801 1.206-.42 3.71 3.733 5.593 9.287z"
        ></path>
        <path
          fill="#FAFAFA"
          d="M52.84 17.386s2.874-1.442 4.014 2.405c1.14 3.848-1.774 4.186-1.774 4.186a25.045 25.045 0 01-2.24-6.591z"
        ></path>
        <path
          fill="#454545"
          d="M50.366 12.693c-.01.24.004.482.04.72.043.46.104 1.129.202 1.953.079.409.162.853.251 1.333.048.239.096.488.144.742l.075.39c.03.13.07.254.106.391l.466 1.656.12.432c.04.148.104.287.154.432.106.29.214.583.325.88l.327.88c.055.143.1.29.166.428l.189.409.727 1.559c.06.122.114.254.176.364l.197.343.375.659.674 1.172c.461.687.849 1.229 1.115 1.605.129.203.275.394.438.57a4.221 4.221 0 00-.362-.62l-1.052-1.646-.645-1.185-.36-.654a22.677 22.677 0 00-.19-.343c-.066-.115-.111-.242-.17-.367l-.71-1.554-.188-.407c-.063-.137-.108-.282-.164-.424l-.322-.873c-.108-.295-.216-.587-.325-.874-.05-.143-.113-.283-.15-.428-.038-.145-.084-.29-.126-.432l-.474-1.643c-.035-.13-.078-.254-.108-.387-.03-.132-.055-.254-.08-.384l-.154-.737c-.1-.473-.196-.916-.282-1.32-.113-.809-.209-1.467-.274-1.935a4.691 4.691 0 00-.131-.705z"
        ></path>
        <path
          fill="#455A64"
          d="M41.245 30.32c-.1.046-.204.08-.312.1l-.856.228c-.725.189-1.724.456-2.827.763-1.102.308-2.096.588-2.824.763-.365.092-.66.16-.866.204a1.88 1.88 0 01-.322.053c.1-.045.205-.08.312-.104l.851-.254 2.817-.807a61.51 61.51 0 012.836-.727c.365-.084.662-.148.869-.186.106-.02.214-.032.322-.033zM37.17 28.876c-.214.121-.44.22-.674.292a28.737 28.737 0 01-3.39 1.022 3.553 3.553 0 01-.723.13 4.58 4.58 0 01.7-.221c.435-.125 1.034-.295 1.696-.493.662-.199 1.258-.39 1.686-.527.23-.087.465-.155.705-.203zM36.085 26.856a18.73 18.73 0 01-2.132.72c-.712.241-1.437.44-2.172.595.697-.283 1.41-.524 2.135-.722.711-.24 1.435-.437 2.17-.593zM35.013 24.91c-.586.332-1.193.624-1.817.876-.606.294-1.23.55-1.868.763a14.81 14.81 0 011.815-.878c.606-.295 1.23-.55 1.87-.76z"
        ></path>
        <path
          fill="#EE4D68"
          d="M27.232 46.798l-4.199-7.16-10.856-.452v.997s-2.98 3.032-3.941 7.16c-.728 3.125 2.333 15.052 3.194 19.188 1.057 5.086 2.517 11.515 2.517 11.515-.22 1.14-1.666 2.063-1.566 3.584l24.128-.267-1.24-4.942-8.037-29.623z"
        ></path>
        <path
          fill="#fff"
          d="M35.603 78.316c0 .038-4.802.066-10.724.066-5.923 0-10.723-.028-10.723-.066 0-.038 4.8-.066 10.723-.066 5.922 0 10.724.03 10.724.066zM33.934 72.192c0 .038-4.757.069-10.624.069-5.867 0-10.626-.031-10.626-.07 0-.037 4.757-.065 10.626-.065 5.87 0 10.625.038 10.625.066zM32.297 65.552c0 .035-4.657.066-10.4.066-5.744 0-10.4-.038-10.4-.066 0-.029 4.653-.067 10.397-.067 5.744 0 10.403.028 10.403.067zM31.153 58.86c0 .036-4.798.066-10.713.066-5.915 0-10.71-.03-10.71-.066 0-.035 4.795-.066 10.71-.066 5.915 0 10.713.028 10.713.066zM28.612 50.56c0 .035-4.53.065-10.136.065-5.606 0-10.136-.03-10.136-.066 0-.035 4.53-.069 10.136-.069 5.605 0 10.136.031 10.136.07zM25.163 42.945c0 .035-3.35.066-7.486.066-4.136 0-7.486-.03-7.486-.066 0-.036 3.35-.066 7.486-.066 4.135 0 7.486.04 7.486.066z"
        ></path>
        <path
          fill="#828282"
          d="M11.324 51.213a19.517 19.517 0 003.95 4.768c2.423 2.035 9.798 5.682 9.798 5.682l.617-1.287 5.502 1.3-1.007-3.424-18.86-7.04z"
          opacity="0.3"
        ></path>
        <path
          fill="#EB9973"
          d="M37.21 42.182l-1.921 10.54-16.451-7.903-2.65 9.375c6.466 3.194 19.554 8.512 21.238 8.36 2.396-.22 4.052-1.667 4.797-5.212.463-2.195.252-16.912.252-16.912l-5.266 1.752z"
        ></path>
        <path
          fill="#EE4D68"
          d="M19.913 43.403s-6.152-3.84-8.835 1c-1.918 4.72.204 8.488 4.941 10.828 2.283 1.13 5.394 2.523 8.535 3.848l4.579-10.267-9.22-5.41z"
        ></path>
        <path
          fill="#454545"
          d="M19.912 43.403s.06.028.17.091l.49.277 1.857 1.073 6.73 3.922.04.026-.02.043-4.566 10.27-.025.058-.058-.026a207.519 207.519 0 01-4.963-2.161c-.747-.34-1.484-.678-2.213-1.018a38.802 38.802 0 01-2.127-1.065c-.335-.195-.667-.4-.994-.613-.312-.229-.634-.438-.918-.692a9.367 9.367 0 01-1.558-1.61l-.313-.453a4.36 4.36 0 01-.274-.467 7.665 7.665 0 01-.448-.972c-.058-.165-.1-.338-.15-.509l-.074-.254c-.02-.086-.03-.17-.048-.254-.027-.168-.058-.339-.085-.509-.028-.17-.02-.336-.03-.509-.019-.33-.013-.66.017-.989.025-.323.093-.638.139-.948.045-.31.153-.606.236-.898.083-.293.204-.567.302-.842a5.07 5.07 0 011.455-1.666 4.473 4.473 0 011.83-.727 7.212 7.212 0 013.131.308c.64.2 1.26.455 1.857.762.204.107.355.194.459.255.103.06.148.097.148.097l-.156-.084c-.103-.056-.252-.138-.466-.24a12.053 12.053 0 00-1.86-.732 7.135 7.135 0 00-3.1-.282 4.344 4.344 0 00-1.79.722 4.974 4.974 0 00-1.42 1.64c-.095.275-.196.552-.297.837-.1.285-.153.585-.229.885-.075.3-.108.618-.133.939-.028.323-.033.649-.015.974 0 .162.017.33.032.508.016.178.056.33.086.496.015.084.025.168.045.255l.073.254c.05.165.09.333.151.496.124.328.272.647.44.954.082.158.172.311.27.46l.31.442c.448.59.966 1.123 1.54 1.587.291.244.595.472.909.684.319.212.648.414.986.606.68.374 1.395.72 2.12 1.06.725.341 1.465.684 2.212 1.018 1.686.763 3.348 1.485 4.956 2.164l-.083.033c1.71-3.815 3.272-7.33 4.588-10.26l.023.067-6.708-3.963-1.835-1.126-.478-.29c-.113-.054-.168-.104-.168-.104z"
        ></path>
        <path
          fill="#EB9973"
          d="M36.96 43.987l.37-3.926s-1.485-3.022-1.454-3.324c.03-.303.115-1.969.115-1.969l3.713-3.377s1.203 0 1.233 1.203c0 0 1.155-.255 1.173 1.154 0 0 1.258.046 1.284 1.071 0 0 .755-.102.73.97-.02.762-.353 3.715-.353 3.715l-1.553 2.729-.128 2.729-5.13-.975zM37.345 56.571c-.042.016-.317-.938-.878-2.006-.561-1.068-1.213-1.816-1.18-1.844.166.138.314.297.44.473a8.486 8.486 0 011.46 2.75c.076.202.13.412.158.627z"
        ></path>
        <path
          fill="#fff"
          d="M16.186 42.098a2.213 2.213 0 01-.164.358c-.113.227-.282.55-.503.947-.425.796-1.032 1.884-1.71 3.08l-1.743 3.051-.529.926a2.61 2.61 0 01-.209.333c.044-.124.099-.244.164-.358.113-.224.282-.55.503-.947.426-.795 1.03-1.884 1.71-3.08a1192.791 1192.791 0 012.272-3.977c.06-.117.13-.228.209-.333zM21.203 44.318a.474.474 0 01-.04.115l-.139.318c-.123.277-.304.67-.538 1.157a92.051 92.051 0 01-1.9 3.748 90.43 90.43 0 01-2.065 3.66c-.277.46-.503.832-.664 1.086l-.186.292a.667.667 0 01-.073.097s.012-.04.05-.112l.166-.302.627-1.11c.53-.933 1.258-2.23 2.029-3.674a180.52 180.52 0 001.933-3.729l.576-1.134.159-.308a.455.455 0 01.065-.104zM26.817 47.492a3.24 3.24 0 01-.154.422 41.92 41.92 0 01-.47 1.122 47.116 47.116 0 01-3.874 7.02c-.287.424-.526.762-.697.994a2.723 2.723 0 01-.274.35c.067-.132.146-.259.234-.378l.66-1.018a62.635 62.635 0 002.05-3.435 63.838 63.838 0 001.815-3.561l.504-1.104c.056-.143.125-.281.206-.412z"
        ></path>
        <path
          fill="#454545"
          d="M12.191 86.337l.524-4.71 23.018-.254.586 4.964H12.191z"
        ></path>
        <path
          fill="#455A64"
          d="M40.693 85.925c0 .036-7.09.066-15.837.066-8.746 0-15.836-.03-15.836-.066 0-.035 7.09-.066 15.836-.066 8.747 0 15.837.026 15.837.066zM27.867 3.782h-8.56a.516.516 0 00-.514.519v.373c0 .287.23.52.513.52h8.56a.516.516 0 00.514-.52v-.373a.516.516 0 00-.513-.52zM30.704 4.489a.712.712 0 01-.432.653.693.693 0 01-.762-.153.71.71 0 01.494-1.207.694.694 0 01.647.436.715.715 0 01.053.27z"
        ></path>
        <path
          fill="#454545"
          d="M1.701 14.75h.564v11.801h-.564a1.546 1.546 0 01-1.103-.462 1.578 1.578 0 01-.457-1.114v-8.648c0-.418.164-.819.457-1.114.292-.296.69-.462 1.103-.462zM1.56 31.884h.567v11.8H1.56c-.414 0-.811-.165-1.104-.461A1.585 1.585 0 010 42.108v-8.647c0-.418.164-.82.457-1.115.293-.296.69-.462 1.104-.462z"
        ></path>
        <path
          fill="#455A64"
          d="M1.6 49.374c-.037 0-.065-8.566-.065-19.13 0-10.565.028-19.133.066-19.133.037 0 .065 8.566.065 19.133 0 10.567-.03 19.13-.065 19.13z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1409_7690">
          <path fill="#fff" d="M0 0H60V89H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

IconCard.defaultProps = {
  className: null,
  rootClassName: null,
};

IconCard.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconCard;
