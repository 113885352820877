import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';

import { ExternalLink, NamedLink } from '../../../components';

import asset1 from './images/asset_1.png';
import asset2 from './images/asset_2.png';

import css from './SectionTeachingResources.module.css';

const SectionTeachingResources = (props) => {

  return (
    <div className={css.root}>
      <div className={css.rootTitleHolder}>
        <h2 className={css.rootTitle}>
          <FormattedMessage id="SectionTeachingResources.sectionTitle" />
        </h2>
      </div>

      <div className={css.textRight}>
        <div className={css.imageHolder}>
          <img  className={css.image} src={asset1} alt='Write textbooks in minutes' />
        </div>

        <div className={css.textHolder}>
          <h3 className={css.textTitle}>
            <FormattedMessage id="SectionTeachingResources.title1" />
          </h3>
          <p className={css.textDescription}>
            <FormattedMessage id="SectionTeachingResources.description1" />
          </p>
          <div className={css.buttonHolder}>
           <ExternalLink
              href="https://www.bookbaker.com/"
              className={css.primaryButton}
            >
              <FormattedMessage id="SectionTeachingResources.btn1" />
            </ExternalLink>
          </div>
        </div>

      </div>

      <div className={css.textLeft}>
        <div className={css.imageHolder}>
          <img className={css.image} src={asset2} alt='Free Resources by Teachers' />
        </div>
        <div className={css.textHolder}>
          <h2 className={css.textTitle}>
            <FormattedMessage id="SectionTeachingResources.title2" />
          </h2>

          <p className={css.textDescription}>
            <FormattedMessage id="SectionTeachingResources.description2" />
          </p>

          <div className={css.buttonHolder}>
            <ExternalLink
              href="https://home.allcourse.com/"
              className={css.primaryButton}
            >
              <FormattedMessage id="SectionTeachingResources.btn2" />
            </ExternalLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionTeachingResources;
