import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import css from './SectionTools.module.css';
import {NamedLink} from "../../../components";

import libraryImg from "./library.svg"
import faqImg from "./faq.svg"
import courseImg from "./course.svg"
import teacherImg from "./teacher.svg"

const SectionTools = () => {

  return (
      <div className={css.root}>
        <h2 className={css.rootTitle}>
          <FormattedMessage id="SectionTools.title" />
        </h2>
        <div className={css.toolsList}>
          {/* TODO: uncomment when functionality is ready */}
          {/* <div className={css.toolsItem}>
            <NamedLink name="NewListingPage" className={css.link}>
              <div className={css.toolsItemImage}>
                <img src={libraryImg} />
              </div>
              <FormattedMessage id="SectionTools.libraryTitle" />
            </NamedLink>
          </div> */}
          <div className={css.toolsItem}>
            <NamedLink name="FAQPage" to={{ hash: '#general' }} className={css.link}>
              <div className={css.toolsItemImage}>
                <img src={faqImg} alt='frequently asked question' />
              </div>
              <FormattedMessage id="SectionTools.faqTitle" />
            </NamedLink>
          </div>
          <div className={css.toolsItem}>
            <NamedLink name="OnlineTeachingJobsPage" className={css.link}>
              <div className={css.toolsItemImage}>
                <img src={teacherImg} alt='Click to join' />
              </div>
              <FormattedMessage id="SectionTools.teacherTitle" />
            </NamedLink>
          </div>
          {/* <div className={css.toolsItem}>
            <NamedLink name="NewListingPage" className={css.link}>
              <div className={css.toolsItemImage}>
                <img src={courseImg} />
              </div>
             <FormattedMessage id="SectionTools.courseTitle" />
            </NamedLink>
          </div> */}
        </div>
      </div>
  );
};

export default SectionTools;
