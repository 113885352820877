import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from '../../LandingPage.module.css';

const IconCard = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="85"
      height="75"
      fill="none"
      viewBox="0 0 85 75"
    >
      <path
        fill="#EE4D68"
        d="M13.818 41.557h21.308V52.74H13.818a4.41 4.41 0 01-4.412-4.412v-2.36a4.413 4.413 0 014.412-4.412z"
      ></path>
      <path
        fill="#EE4D68"
        d="M29.555 41.557h44.944a.733.733 0 01.727.732v.4a.733.733 0 01-.732.733h-44.94v-1.865zM29.555 50.883h44.944a.732.732 0 01.732.733v.392a.733.733 0 01-.732.733H29.555v-1.858z"
      ></path>
      <path
        fill="#F5F5F5"
        d="M73.868 50.884H34.652a3.734 3.734 0 01-3.734-3.738 3.735 3.735 0 013.734-3.734h39.216a9.25 9.25 0 000 7.472z"
      ></path>
      <path
        fill="#000"
        d="M30.916 52.74H13.901a4.498 4.498 0 01-4.499-4.499V46.02a4.465 4.465 0 014.464-4.465h17.473c-4.047 2.455-3.744 8.33-.423 11.187z"
        opacity="0.3"
      ></path>
      <path
        fill="#454545"
        d="M69.107 47.494c0 .037-8.05.067-17.977.067-9.928 0-17.978-.03-17.978-.067 0-.038 8.048-.068 17.978-.068 9.93 0 17.977.03 17.977.068zM72.802 49.154c0 .037-8.05.067-17.977.067s-17.977-.03-17.977-.067c0-.038 8.047-.065 17.977-.065s17.977.03 17.977.065zM71.715 45.139c0 .037-8.05.067-17.977.067-9.928 0-17.98-.03-17.98-.067 0-.038 8.047-.068 17.98-.068 9.932 0 17.977.035 17.977.068zM28.951 51.956c0 .037-3.769.067-8.415.067-4.646 0-8.415-.03-8.415-.067 0-.038 3.766-.068 8.415-.068 4.649 0 8.415.03 8.415.068zM28.951 42.484c0 .038-3.769.068-8.415.068-4.646 0-8.415-.03-8.415-.068 0-.037 3.766-.067 8.415-.067 4.649 0 8.415.03 8.415.067z"
      ></path>
      <path
        fill="#EE4D68"
        d="M4.411 60.593h28.712v14.404H4.41A4.41 4.41 0 010 70.585v-5.582a4.413 4.413 0 014.411-4.41z"
      ></path>
      <path
        fill="#EE4D68"
        d="M25.95 60.593h58.092a.732.732 0 01.733.732v.928a.733.733 0 01-.733.732H25.95v-2.392zM25.95 72.605h58.092a.732.732 0 01.733.732v.928a.732.732 0 01-.733.732H25.95v-2.392z"
      ></path>
      <path
        fill="#F5F5F5"
        d="M83.016 72.605h-50.5a4.81 4.81 0 010-9.622h50.5a11.898 11.898 0 000 9.622z"
      ></path>
      <path
        fill="#000"
        d="M27.702 74.994H4.352A4.35 4.35 0 010 70.64v-5.535a4.5 4.5 0 014.499-4.5h23.743c-5.206 3.145-4.816 10.712-.54 14.39z"
        opacity="0.3"
      ></path>
      <path
        fill="#454545"
        d="M76.882 68.24c0 .037-10.367.067-23.15.067-12.784 0-23.154-.03-23.154-.067 0-.038 10.365-.068 23.153-.068 12.79 0 23.151.03 23.151.068zM81.646 70.377c0 .038-10.367.068-23.153.068-12.786 0-23.153-.03-23.153-.068 0-.037 10.364-.065 23.153-.065 12.789 0 23.153.043 23.153.065zM80.253 66.255c0 .038-10.364.068-23.15.068-12.787 0-23.154-.03-23.154-.068 0-.037 10.365-.067 23.154-.067 12.788 0 23.15.03 23.15.067zM25.166 73.984c0 .038-4.851.068-10.837.068s-10.837-.03-10.837-.068c0-.037 4.851-.065 10.837-.065s10.837.03 10.837.065zM25.166 61.788c0 .035-4.851.065-10.837.065s-10.837-.03-10.837-.065 4.851-.067 10.837-.067 10.837.03 10.837.067z"
      ></path>
      <path
        fill="#455A64"
        d="M18.066 52.398h23.658v8.25H18.066a3.249 3.249 0 01-3.25-3.25v-1.75a3.25 3.25 0 013.25-3.25z"
      ></path>
      <path
        fill="#455A64"
        d="M35.895 52.398H82.92a.767.767 0 01.767.768v.41a.767.767 0 01-.767.767H35.894V52.398zM35.895 58.703H82.92a.767.767 0 01.767.767v.41a.768.768 0 01-.767.768H35.894v-1.945z"
      ></path>
      <path
        fill="#F5F5F5"
        d="M82.251 58.7H39.514a2.182 2.182 0 010-4.362H82.25c-1.093 1.455-1.093 2.91 0 4.363z"
      ></path>
      <path
        fill="#000"
        d="M37.32 60.643H18.077a3.27 3.27 0 01-3.271-3.275v-1.63a3.342 3.342 0 013.339-3.342h19.622c-4.237 1.81-3.92 6.142-.445 8.247z"
        opacity="0.3"
      ></path>
      <path
        fill="#454545"
        d="M77.275 56.176c0 .037-8.422.067-18.81.067-10.386 0-18.809-.03-18.809-.067 0-.038 8.42-.065 18.81-.065 10.39 0 18.81.03 18.81.065zM81.146 57.356c0 .037-8.422.067-18.81.067-10.386 0-18.809-.03-18.809-.067 0-.038 8.42-.068 18.81-.068 10.39 0 18.81.025 18.81.068zM35.266 59.823c0 .038-3.941.068-8.805.068-4.863 0-8.805-.03-8.805-.068 0-.037 3.942-.067 8.805-.067 4.864 0 8.805.03 8.805.067zM35.266 53.368c0 .038-3.941.066-8.805.066-4.863 0-8.805-.028-8.805-.066 0-.037 3.942-.067 8.805-.067 4.864 0 8.805.03 8.805.067zM83.126 52.396c0 .037-14.464.067-32.298.067-17.835 0-32.309-.03-32.309-.067 0-.038 14.459-.068 32.301-.068 17.842 0 32.306.028 32.306.068z"
      ></path>
      <path
        fill="#EE4D68"
        d="M57.191 45.019v4.48l1.552-.998 1.732.998v-4.48h-3.284zM80.938 26.425v20.476a.162.162 0 00.322 0V26.425a.162.162 0 00-.323 0z"
      ></path>
      <path
        fill="#EE4D68"
        d="M82.25 42.39a1.155 1.155 0 11-2.309 0 1.155 1.155 0 012.31 0z"
      ></path>
      <path
        fill="#EE4D68"
        d="M80.777 42.986s-.842.843-1.095 3.31c-.252 2.468-.307 3.7-.307 3.7l2.27.14v-1.765c0 .083.45 1.317.45 1.317l1.794-.59s-.638-6.559-3.112-6.111z"
      ></path>
      <path
        fill="#454545"
        d="M68.972 28.102H30.434V42h38.538V28.102zM82.512 25.71H16.594v2.392h65.918V25.71z"
      ></path>
      <path
        fill="#000"
        d="M30.434 38.054s11.706-4.2 19.269-5.75c7.563-1.55 19.27-1.034 19.27-1.034v-3.163h-38.54v9.947zM40.437 25.71H16.594v2.392h23.843V25.71z"
        opacity="0.3"
      ></path>
      <path
        fill="#455A64"
        d="M82.032 28.108c0 .037-14.658.067-32.74.067-18.083 0-32.741-.03-32.741-.067 0-.038 14.653-.065 32.74-.065 18.088 0 32.74.022 32.74.065z"
      ></path>
      <path
        fill="#455A64"
        d="M40.396 28.12c-.09-.807-.09-1.622 0-2.43.086.808.086 1.622 0 2.43zM45.893 42c-.035 0-.065-2.713-.065-6.058 0-3.345.03-6.055.065-6.055s.068 2.71.068 6.055c0 3.345-.03 6.057-.068 6.057z"
      ></path>
      <path
        fill="#F4A517"
        d="M46.726 5.537a5.125 5.125 0 11-7.188-.912 5.117 5.117 0 017.188.912z"
      ></path>
      <path
        fill="#EFCA80"
        d="M46.53 5.698a4.868 4.868 0 11-6.822-.815 4.859 4.859 0 016.822.815z"
      ></path>
      <path
        fill="#F4A517"
        d="M45.718 6.337a3.834 3.834 0 11-6.018 4.75 3.834 3.834 0 016.018-4.75z"
      ></path>
      <path
        fill="#EFCA80"
        d="M43.916 10.757l-.345-.437a2.196 2.196 0 01-.933.41l-.282-.644c.335-.049.651-.184.918-.391.263-.204.366-.44.223-.633-.144-.191-.366-.166-.8-.012-.616.218-1.133.25-1.479-.195a1.077 1.077 0 01.133-1.42l-.336-.438.445-.355.315.406c.243-.184.525-.31.824-.371l.27.619c-.285.06-.552.186-.781.367-.304.225-.297.433-.196.56.101.127.352.124.863-.038.702-.228 1.12-.128 1.443.26a1.12 1.12 0 01-.16 1.492l.342.481-.464.34z"
      ></path>
      <path
        fill="#F4A517"
        d="M72.16 25.377a7.887 7.887 0 11-14.858-5.296 7.887 7.887 0 0114.858 5.296z"
      ></path>
      <path
        fill="#EFCA80"
        d="M71.783 25.239a7.49 7.49 0 11-14.113-5.021 7.49 7.49 0 0114.113 5.02z"
      ></path>
      <path
        fill="#F4A517"
        d="M70.28 24.709a5.891 5.891 0 11-11.087-3.991 5.891 5.891 0 0111.087 3.99z"
      ></path>
      <path
        fill="#EFCA80"
        d="M63.056 26.007l.283-.807a3.737 3.737 0 01-1.31-.879l.61-.892c.36.392.809.69 1.31.866.492.174.883.105 1.002-.235.118-.34-.072-.621-.63-1.054-.796-.622-1.258-1.271-.965-2.092a1.66 1.66 0 011.957-.986l.296-.796.82.293-.266.745c.418.157.8.397 1.122.706l-.579.865a3.137 3.137 0 00-1.123-.718c-.542-.192-.8-.018-.882.222-.081.239.124.554.746 1.124.881.722 1.12 1.324.855 2.08a1.707 1.707 0 01-2.061 1.023l-.312.87-.873-.335z"
      ></path>
      <path
        fill="#F4A517"
        d="M47.404 37.645a8.765 8.765 0 11-17.402 2.112 8.765 8.765 0 0117.402-2.112z"
      ></path>
      <path
        fill="#EFCA80"
        d="M46.97 37.695a8.33 8.33 0 11-16.537 2.036 8.33 8.33 0 0116.537-2.036z"
      ></path>
      <path
        fill="#F4A517"
        d="M45.22 37.92a6.564 6.564 0 11-7.297-5.724 6.553 6.553 0 017.298 5.723z"
      ></path>
      <path
        fill="#EFCA80"
        d="M38.673 42.806l-.108-.943a4.04 4.04 0 01-1.73-.213l.15-1.228c.538.216 1.12.299 1.697.242.573-.066.93-.337.885-.73-.045-.395-.393-.573-1.162-.739-1.102-.225-1.887-.631-1.997-1.587a1.815 1.815 0 011.459-1.957l-.108-.944.967-.11.1.871c.496-.052.997.002 1.47.159l-.148 1.142a3.246 3.246 0 00-1.47-.158c-.633.072-.815.383-.772.657.044.273.407.486 1.29.699 1.243.281 1.77.766 1.883 1.648a1.911 1.911 0 01-1.532 2.05l.115 1.004-.989.138z"
      ></path>
      <path
        fill="#F7F7FC"
        d="M32.84 34.75a3.49 3.49 0 00.253-.755c.143.227.31.44.496.633.246.11.503.194.767.25-.23.145-.442.315-.631.509a3.49 3.49 0 00-.253.755 3.455 3.455 0 00-.508-.632 3.48 3.48 0 00-.755-.252c.23-.144.442-.315.632-.508z"
      ></path>
    </svg>
  );
};

IconCard.defaultProps = {
  className: null,
  rootClassName: null,
};

IconCard.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconCard;
