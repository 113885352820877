import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import Cookies from 'js-cookie';
import { intlShape, FormattedMessage } from '../../../util/reactIntl';
import {SocialLoginButton} from '../../../components';

import css from './SectionHero.module.css';
import {GoogleLogo} from "../../AuthenticationPage/socialLoginLogos";
import routeConfiguration from "../../../routing/routeConfiguration";
import {apiBaseUrl} from "../../../util/api";
import {pathByRouteName} from "../../../util/routes";
const showGoogleLogin = !!process.env.REACT_APP_GOOGLE_CLIENT_ID;

const GoogleAuthButton = props => {
  const [authInfo, setAuthInfo] = useState(null);
  const {
    location,
  } = props;


  useEffect(() => {
    Cookies.get('st-authinfo')
      ? setAuthInfo(JSON.parse(Cookies.get('st-authinfo').replace('j:', '')))
      : setAuthInfo(null);
  }, [authInfo])


  const googleButtonText = (
    <FormattedMessage id="AuthenticationPage.signupWithGoogle" />
  );

  const locationFrom = location.state && location.state.from ? location.state.from : null;
  const authinfoFrom =
    authInfo && authInfo.from ? authInfo.from : null;
  const from = locationFrom ? locationFrom : authinfoFrom ? authinfoFrom : null;

  const getDefaultRoutes = () => {
    const routes = routeConfiguration();
    const baseUrl = apiBaseUrl();

    // Route where the user should be returned after authentication
    // This is used e.g. with EditListingPage and ListingPage
    const fromParam = from ? `from=${from}` : '';

    // Default route where user is returned after successfull authentication
    const defaultReturn = pathByRouteName('LandingPage', routes);
    const defaultReturnParam = defaultReturn ? `&defaultReturn=${defaultReturn}` : '';

    // Route for confirming user data before creating a new user
    const defaultConfirm = pathByRouteName('ConfirmPage', routes);
    const defaultConfirmParam = defaultConfirm ? `&defaultConfirm=${defaultConfirm}` : '';

    return { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam };
  };

  const authWithGoogle = () => {
    const defaultRoutes = getDefaultRoutes();
    const { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam } = defaultRoutes;
    window.location.href = `${baseUrl}/api/auth/google?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
  };

  return (
    <>
      {showGoogleLogin ? (
        <div className={css.socialButtonWrapper}>
          <SocialLoginButton onClick={() => authWithGoogle()}>
            <span className={css.buttonIcon}>{GoogleLogo}</span>
            {googleButtonText}
          </SocialLoginButton>
        </div>
      ) : null}
    </>
  );
};

GoogleAuthButton.defaultProps = { rootClassName: null, className: null };

GoogleAuthButton.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};


export default GoogleAuthButton;
