import React, {useEffect, useRef, useState} from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { intlShape, injectIntl, FormattedMessage } from '../../../util/reactIntl';
import { Button, NamedLink } from '../../../components';
import GoogleAuthButton from './GoogleAuthButton';
import FacebookAuthButton from './FacebookAuthButton';
import Typed from 'react-typed';
import './animatedCursor.css';

import mainImg from "./homepage-img.png"
import mathImg from "./math.png"
import englishImg from "./english.png"
import scienceImg from "./science.png"
import historyImg from "./history.png"
import languagesImg from "./languages.png"
import artsImg from "./arts.png"

import css from './SectionHero.module.css';
const imagesArray = [mathImg, englishImg, scienceImg, historyImg, languagesImg, artsImg];


const SectionHero = props => {
  const [mounted, setMounted] = useState(false);
  const [index, setIndex] = useState(-1);
  const [isLastItem, setIsLastItem] = useState(false);
  const [initialEmail, setEmail] = useState(null)
  const inputRef = useRef();
  const {
    rootClassName,
    className,
    currentUser,
    handleOpenInvetePopup,
    intl,
    location,
    handleGetStarted,
  } = props;

  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className, {[css.resetBgTransition]: isLastItem});

  const preStringTyped = () => {
    setTimeout(
      () => setIndex(index => index + 1),
      1
    );

    if (index === imagesArray.length - 1) {
      setIndex(-1);
      setIsLastItem(true);
    }
  }

  const mainContainerClass = `coloredContainer_${index}`;


  return (
    <div className={classes}>
      <div className={classNames(css.gradientContainer, mainContainerClass)} data-image={`image_${index}`}>
        <div className={css.heroContent}>
          <div className={css.heroContentText}>
            <h1 className={classNames(css.heroMainTitle, { [css.heroMainTitleFEDelay]: mounted })}>
              <span className={css.mainTypedText}>
                <FormattedMessage id="SectionHero.title1" />
              </span>
              <Typed
                strings={[
                  "Math",
                  "English",
                  "Science",
                  "History",
                  "Languages",
                  "Arts"
                ]}
                typeSpeed={120}
                backSpeed={160}
                loop
                smartBackspace
                className={css.heroTypeString}
                preStringTyped={preStringTyped}
              />
              <br />
              <span className={css.mainTypedText}>
                <FormattedMessage id="SectionHero.title2" className={css.mainTypedText} />
              </span>
            </h1>
            <h2 className={classNames(css.heroSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
              <FormattedMessage id="SectionHero.subTitle" />
            </h2>

            {!currentUser && (
              <div className={css.emailContainer}>
                <Button className={classNames(css.heroButton, { [css.heroButtonFEDelay]: mounted })} onClick={handleGetStarted}>
                  <FormattedMessage id="SectionHero.signUpJoinBtn" />
                </Button>
              </div>
            )}
          </div>

          <div className={css.heroContentImg}>
            <HeroImage imageIndex={index}/>
          </div>
        </div>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

const HeroImage = (props) => {
  const { imageIndex } = props;
  const i = imageIndex === -1 ? 0 : imageIndex;

  return <img src={imagesArray[i]} alt="AllCourse" />
}

export default injectIntl(SectionHero);
