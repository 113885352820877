import React, { useState } from 'react';
import PropTypes, { array } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { shuffle } from 'lodash';
import config from '../../config';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';

import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  Modal,
  ModalInviteFriend,
  NamedLink,
} from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';

import facebookImage from '../../assets/allCourseFacebook-1200x630.jpg';
import twitterImage from '../../assets/allCourseTwitter-600x314.jpg';

import SectionHero from './SectionHero/SectionHero';
import SectionWhyAllCourse from './SectionWhyAllCourse/SectionWhyAllCourse';
import SectionPossibilities from './SectionPossibilities/SectionPossibilities';
import SectionHomeDescription from './SectionHomeDescription/SectionHomeDescription';
import SectionTeachersCarousel from './SectionTeachersCarousel/SectionTeachersCarousel';
import SectionListingsCarousel from './SectionListingsCarousel/SectionListingsCarousel';
import SectionTeachingResources from './SectionTeachingResources/SectionTeachingResources';
import SectionNews from './SectionNews/SectionNews';

import SectionTeachers from './SectionTeachers/SectionTeachers';
import SectionAdmin from './SectionAdmin/SectionAdmin';
import SectionTools from './SectionTools/SectionTools';
import SectionPublishCourse from './SectionPublishCourse/SectionPublishCourse';

import css from './LandingPage.module.css';
import { getListingsById, getMarketplaceEntities } from "../../ducks/marketplaceData.duck";
import { propTypes } from "../../util/types";
import { SearchPageComponent } from "../SearchPage/SearchPageWithList";
import { types as sdkTypes } from "../../util/sdkLoader";
import classNames from 'classnames';

const { Money } = sdkTypes;

export const LandingPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    currentUser,
    teachers,
    onManageDisableScrolling,
    listings,
    reviews,
    transactions,
  } = props;

  const [isOpenModal, setOpenModal] = useState(false);

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
  const filteredTeachers = teachers?.filter(t => {
    const { profile } = t?.attributes;
    const { publicData } = profile;
    const { onboardingProcess } = publicData;
    const isNewUser = onboardingProcess && onboardingProcess?.licenses && onboardingProcess?.licenses.length > 0;
    const isTeacherHasState = onboardingProcess && onboardingProcess?.licenses && onboardingProcess?.licenses[0]?.usState !== null;
    if (isNewUser) {
      return isTeacherHasState
    } else {
      return t
    }
  });

  const teachersIds = teachers && teachers.length > 0 ? teachers?.map(t => t?.id?.uuid) : [];
  const listingsWithTeachers = listings && teachersIds && listings.length > 0 && teachersIds.length > 0 ? listings.filter(item => !teachersIds.includes(item?.author?.id?.uuid)) : [];
  const filteredListings = listingsWithTeachers.filter(l => l.attributes.price instanceof Money);

  const randomTeachers = shuffle(filteredTeachers);
  const randomListings = shuffle(filteredListings);

  const [invitePopUpOpen, setInvitePopUpOpen] = useState(false);

  const teachersCustomCard = config?.custom?.teachersCustomCard;
  const listingCustomCard = config?.custom?.listingCustomCard;

  return (
    <Page
      referrer="strict-origin-when-cross-origin"
      currentUser={currentUser}
      pageName='LandingPage'
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer
            isOpenSigUpModal={isOpenModal}
            setOpenSigUpModal={setOpenModal}
            newSearchDesign
            teacherTopbar
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer}>
            <SectionHero
              rootClassName={css.heroRoot}
              className={css.hero}
              history={history}
              location={location}
              currentUser={currentUser}
              handleOpenInvetePopup={() => setInvitePopUpOpen(true)}
              handleGetStarted={() => setOpenModal(true)}
            />
          </div>
          <ul className={css.sections}>
            <li className={css.section}>
              <div className={css.sectionContentFirstChild}>
                <SectionWhyAllCourse />
              </div>
            </li>
            {/* <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionHomeDescription />
              </div>
            </li> */}

            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionPossibilities
                  currentUser={currentUser}
                  handleGetStarted={() => setOpenModal(true)}
                />
              </div>
            </li>

            <li className={css.section}>
              <div className={css.sectionContentFirstChild}>
                <SectionTeachersCarousel teachers={randomTeachers?.length > 0 ? randomTeachers : teachersCustomCard} currentUser={currentUser} isCustomCard={randomTeachers?.length === 0} />
                <div className={css.buttonHolder}>
                  <NamedLink name="SearchPage" to={{ search: 'type=teacher' }} className={css.primaryButton}>
                    <FormattedMessage id="LandingPage.allTeachersBtnText" />
                  </NamedLink>
                </div>
              </div>
            </li>

            <li className={css.section}>
              <div className={css.sectionContentFirstChild}>
                <SectionListingsCarousel listings={randomListings?.length > 0 ? randomListings : listingCustomCard} isCustomCard={randomListings?.length === 0} />
                <div className={css.buttonHolder}>
                  <NamedLink name="SearchPage" to={{ search: 'type=course' }} className={css.primaryButton}>
                    <FormattedMessage id="LandingPage.allListingsBtnText" />
                  </NamedLink>
                </div>
              </div>
            </li>

            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionTeachingResources />
              </div>

            </li>

            {/* <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionTeachers onManageDisableScrolling={onManageDisableScrolling} currentUser={currentUser} />
              </div>
            </li> */}
            {/* <li className={css.sectionContentLastChild}>
              <div className={css.sectionContent}>
                <SectionAdmin />
              </div>
            </li> */}
            <li>
              <div className={css.sectionContent}>
                <SectionNews intl={intl} />
              </div>
            </li>
            <li className={css.sectionContentLastChild}>
              <div className={css.sectionContent}>
                <SectionTools />
              </div>
            </li>
            {/* <li>
              <div className={css.sectionContent}>
                <SectionReviews intl={intl} transactions={transactions}/>
              </div>

            </li> */}
            <li className={css.section}>
              <div className={css.sectionPublishCourse}>
                <div className={css.sectionContent}>
                  <SectionPublishCourse
                    currentUser={currentUser}
                    intl={intl}
                    location={location}
                    handleGetStarted={() => setOpenModal(true)}
                  />
                </div>
              </div>
            </li>
            {/* <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionHowItWorks />
              </div>
            </li> */}
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>

      <Modal
        id="TopbarInviteFriend"
        isOpen={invitePopUpOpen}
        onClose={() => setInvitePopUpOpen(false)}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
        closeButtonMessage={" "}
      >
        <ModalInviteFriend
          currentUser={currentUser}
          currentPage={"LandingPage"}
          onManageDisableScrolling={onManageDisableScrolling}
        />
      </Modal>
    </Page>
  );
};

const { bool, object } = PropTypes;

SearchPageComponent.defaultProps = {
  listings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  teachers: [],
  reviews: [],
};

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  listings: array,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,

  teachers: array,

  fetchTransactionsInProgress: bool.isRequired,
  fetchTransactionsError: propTypes.error,
  reviews: array,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    searchTeachersInProgress,
    searchTeachersError,
    teachers,
    currentPageResultIds,
    searchInProgress,
    searchListingsError,
    fetchTransactionsInProgress,
    fetchTransactionsError,
    reviews,
    transactionRefs
  } = state.LandingPage;

  const pageListings = getListingsById(state, currentPageResultIds);

  return {
    currentUser,
    searchTeachersInProgress,
    searchTeachersError,
    teachers,
    scrollingDisabled: isScrollingDisabled(state),
    listings: pageListings,
    searchInProgress,
    searchListingsError,
    fetchTransactionsInProgress,
    fetchTransactionsError,
    reviews,
    transactions: getMarketplaceEntities(state, transactionRefs),
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) => dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
