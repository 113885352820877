import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from '../../LandingPage.module.css';

const IconCard = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="77" height="82" viewBox="0 0 77 82" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1919_10253)">
        <path opacity="0.14" d="M32.6033 2.45467L1.1021 55.7832C0.916271 56.0978 1.01974 56.504 1.33322 56.6905L40.3618 79.9098C40.6753 80.0963 41.0801 79.9925 41.2659 79.6779L72.7671 26.3494C72.9529 26.0348 72.8495 25.6286 72.536 25.4421L33.5074 2.22274C33.1939 2.03624 32.7891 2.14008 32.6033 2.45467Z" fill="#23A0EC"/>
        <path d="M31.5916 0.317219L0.0903811 53.6457C-0.0954482 53.9603 0.00802618 54.3665 0.321497 54.553L39.3501 77.7724C39.6636 77.9589 40.0683 77.855 40.2542 77.5404L71.7554 24.2119C71.9412 23.8973 71.8378 23.4911 71.5243 23.3046L32.4957 0.0852871C32.1822 -0.101206 31.7774 0.00262921 31.5916 0.317219Z" fill="#EB94BD"/>
        <path opacity="0.14" d="M50.5554 77.1248L5.94044 68.8671C5.7683 68.8358 5.6156 68.7371 5.51595 68.5928C5.41629 68.4485 5.37782 68.2704 5.40901 68.0976L16.6082 7.13002C16.6237 7.04412 16.6559 6.96213 16.703 6.88877C16.7502 6.81541 16.8113 6.75212 16.8829 6.70255C16.9545 6.65299 17.0352 6.61812 17.1202 6.59995C17.2053 6.58178 17.2931 6.58068 17.3786 6.59669L61.9936 14.8543C62.166 14.8865 62.3187 14.9859 62.4183 15.1308C62.5179 15.2757 62.5563 15.4543 62.525 15.6275L51.3258 76.595C51.31 76.6806 51.2774 76.7622 51.2301 76.8352C51.1828 76.9081 51.1216 76.971 51.0501 77.0202C50.9785 77.0694 50.898 77.104 50.8131 77.1219C50.7282 77.1399 50.6407 77.1408 50.5554 77.1248Z" fill="#23A0EC"/>
        <path d="M15.765 4.76125L4.33203 67.0322L50.2498 75.5231L61.6828 13.2521L15.765 4.76125Z" fill="white"/>
        <path opacity="0.39" d="M15.6454 5.41246L4.4516 66.381C4.38556 66.7407 4.62256 67.0859 4.98094 67.1522L49.6009 75.4031C49.9593 75.4694 50.3034 75.2315 50.3694 74.8719L61.5632 13.9033C61.6293 13.5437 61.3923 13.1984 61.0339 13.1321L16.4139 4.88124C16.0555 4.81497 15.7114 5.0528 15.6454 5.41246Z" fill="#EB94BD"/>
        <g clip-path="url(#clip1_1919_10253)">
          <path d="M40.7681 47.7448C46.0139 43.5129 46.8176 35.8522 42.5632 30.6341C38.3088 25.416 30.6074 24.6165 25.3616 28.8484C20.1158 33.0803 19.3121 40.7411 23.5665 45.9592C27.8209 51.1773 35.5223 51.9767 40.7681 47.7448Z" fill="#2D61A9"/>
          <path d="M36.767 30.5215C36.4369 30.1636 35.9494 29.9512 35.3047 29.8843C34.8023 29.8252 34.293 29.8883 33.8206 30.0681C33.388 30.2284 33.0048 30.4981 32.7089 30.8505C32.4279 31.1869 32.255 31.5995 32.2128 32.0348C32.1819 32.267 32.2032 32.5033 32.2753 32.7263C32.3474 32.9494 32.4685 33.1537 32.6299 33.3244C32.9599 33.6829 33.4541 33.896 34.1125 33.9638C34.6106 34.023 35.1158 33.9629 35.5859 33.7887C36.0169 33.6305 36.3972 33.3598 36.6869 33.0051C36.9645 32.6709 37.1366 32.2626 37.1819 31.8315C37.2145 31.5962 37.1942 31.3566 37.1225 31.1301C37.0507 30.9035 36.9293 30.6957 36.767 30.5215Z" fill="white"/>
          <path d="M34.961 44.8432C34.961 44.8432 32.9445 45.4104 33.2238 44.4382L35.3349 37.0995C35.3962 36.8848 35.4096 36.6593 35.3741 36.4388C35.3386 36.2184 35.2551 36.0083 35.1293 35.8234C35.0036 35.6385 34.8387 35.4831 34.6462 35.3683C34.4537 35.2534 34.2383 35.1819 34.0151 35.1586L30.3276 34.7758L30.1688 35.2292C30.1688 35.2292 31.5472 35.4477 31.2117 36.6182C30.4459 39.2824 29.0482 44.1425 29.0469 44.1473C28.955 44.4704 28.9465 44.8114 29.0221 45.1387C29.0977 45.466 29.2549 45.7691 29.4794 46.0199C30.1601 46.7761 31.1963 46.8386 32.1342 46.644C32.4485 46.5791 35.2165 45.6766 35.2134 45.6673L34.961 44.8432Z" fill="white"/>
          <path opacity="0.1" d="M41.1275 29.1528C42.5422 31.6548 43.0215 34.5739 42.4807 37.3937C41.9398 40.2134 40.4137 42.752 38.1723 44.5601C35.9309 46.3683 33.1188 47.3295 30.2335 47.2738C27.3482 47.218 24.5758 46.1487 22.4067 44.2553C23.2788 45.7991 24.4793 47.1348 25.9242 48.1692C27.3692 49.2035 29.0237 49.9116 30.7724 50.2438C32.521 50.576 34.3214 50.5244 36.0479 50.0925C37.7743 49.6607 39.3852 48.859 40.7679 47.7436C42.1505 46.6282 43.2716 45.2259 44.0529 43.6347C44.8341 42.0435 45.2566 40.3018 45.2909 38.5313C45.3251 36.7608 44.9703 35.0043 44.2512 33.3844C43.532 31.7645 42.466 30.3203 41.1275 29.1528Z" fill="#272525"/>
          <path opacity="0.2" d="M36.8737 28.248L37.0368 28.2649C35.0687 27.3197 32.8437 27.0409 30.7014 27.4711C28.5591 27.9014 26.6172 29.017 25.1719 30.6477C25.1379 30.6554 25.1073 30.6735 25.0844 30.6996C25.0615 30.7257 25.0476 30.7583 25.0446 30.7928C23.754 32.3091 22.9545 34.178 22.751 36.1544C22.7462 36.1998 22.7413 36.246 22.7366 36.2915C23.9469 33.645 25.9634 31.4443 28.5005 30.0008C31.0376 28.5573 33.9669 27.9441 36.8737 28.248Z" fill="white"/>
        </g>
        <path opacity="0.21" d="M49.0764 53.6342C57.8918 44.7873 57.8918 30.4436 49.0764 21.5968C40.261 12.7499 25.9685 12.7499 17.1531 21.5968C8.33769 30.4437 8.33769 44.7873 17.1531 53.6342C25.9685 62.4811 40.261 62.4811 49.0764 53.6342Z" fill="white"/>
        <path opacity="0.76" d="M15.4267 39.2407C15.0483 39.2407 14.6854 39.0899 14.4179 38.8214C14.1503 38.5529 14 38.1887 14 37.809C14.0085 32.3129 16.1883 27.0445 20.0614 23.1588C23.9346 19.2732 29.185 17.0874 34.6615 17.0808C35.0399 17.0808 35.4027 17.2317 35.6703 17.5002C35.9378 17.7687 36.0881 18.1328 36.0881 18.5126C36.0881 18.8923 35.9378 19.2565 35.6703 19.525C35.4027 19.7935 35.0399 19.9443 34.6615 19.9443C29.9436 19.9538 25.4217 21.8391 22.0859 25.1874C18.7502 28.5358 16.8725 33.0742 16.864 37.809C16.864 37.9979 16.8268 38.185 16.7544 38.3594C16.682 38.5338 16.576 38.6921 16.4424 38.8252C16.3088 38.9583 16.1502 39.0635 15.9759 39.1349C15.8016 39.2062 15.6149 39.2422 15.4267 39.2407Z" fill="white"/>
        <path opacity="0.58" d="M33.1125 60.9927C28.508 60.9892 24.008 59.6158 20.1811 57.0462C16.3543 54.4767 13.3723 50.8262 11.6123 46.5563C9.85217 42.2863 9.39293 37.5885 10.2926 33.0567C11.1923 28.5249 13.4104 24.3625 16.6668 21.0955C19.9231 17.8285 24.0714 15.6037 28.5873 14.7022C33.1033 13.8007 37.7842 14.2631 42.0384 16.0307C46.2926 17.7984 49.9291 20.7921 52.4884 24.6334C55.0476 28.4748 56.4147 32.9913 56.4168 37.6122C56.4121 43.8129 53.9551 49.7581 49.5855 54.142C45.2159 58.5259 39.2911 60.9899 33.1125 60.9927ZM33.1125 15.6849C28.7893 15.6885 24.5642 16.9784 20.9715 19.3916C17.3788 21.8048 14.5797 25.2329 12.9283 29.2425C11.2768 33.2521 10.8471 37.6631 11.6934 41.9178C12.5398 46.1724 14.6242 50.0797 17.6831 53.1456C20.7421 56.2114 24.6381 58.2982 28.8788 59.142C33.1194 59.9859 37.5141 59.5488 41.5072 57.8862C45.5004 56.2236 48.9126 53.4101 51.3126 49.8014C53.7125 46.1927 54.9923 41.9508 54.9902 37.6122C54.9836 31.7928 52.6759 26.2139 48.574 22.1007C44.472 17.9874 38.9111 15.6761 33.1125 15.6742V15.6849Z" fill="#23A0EC"/>
        <path d="M53.8634 53.8337C53.8974 53.802 53.9331 53.7721 53.9704 53.7443C58.0766 48.4159 60.0082 41.719 59.3731 35.0133C58.738 28.3076 55.5837 22.0959 50.5508 17.6393C45.5179 13.1828 38.9836 10.8156 32.2747 11.0184C25.5658 11.2212 19.1853 13.9787 14.4287 18.7311C9.67212 23.4836 6.89607 29.8746 6.66422 36.6065C6.43237 43.3384 8.76211 49.9065 13.1804 54.9771C17.5986 60.0478 23.7742 63.2409 30.4531 63.9081C37.1321 64.5752 43.8136 62.6664 49.1412 58.5693L52.1906 55.5125L53.8634 53.8337ZM33.1127 60.2766C28.6494 60.2731 24.2873 58.9417 20.5777 56.4508C16.8681 53.9599 13.9776 50.4212 12.2715 46.2821C10.5654 42.143 10.1203 37.5891 10.9925 33.1962C11.8646 28.8032 14.0149 24.7683 17.1714 21.6015C20.328 18.4347 24.3492 16.278 28.7267 15.4041C33.1043 14.5303 37.6418 14.9784 41.7657 16.6919C45.8895 18.4054 49.4147 21.3074 51.8955 25.031C54.3764 28.7546 55.7017 33.1327 55.7038 37.612C55.7052 40.5894 55.1218 43.5378 53.9869 46.2887C52.852 49.0396 51.1879 51.539 49.0897 53.644C46.9916 55.749 44.5005 57.4182 41.7591 58.5563C39.0176 59.6944 36.0795 60.279 33.1127 60.2766Z" fill="#AFCDFB"/>
        <path d="M53.2062 57.9788L52.1861 56.9515C52.0917 56.858 52.0167 56.7465 51.9655 56.6236C51.9143 56.5007 51.8879 56.3688 51.8879 56.2357C51.8879 56.1025 51.9143 55.9706 51.9655 55.8477C52.0167 55.7248 52.0917 55.6133 52.1861 55.5198L49.1367 58.5766L49.0867 58.6267C48.8986 58.8171 48.793 59.0744 48.793 59.3426C48.793 59.6107 48.8986 59.868 49.0867 60.0584L50.3279 61.3005C50.7417 60.6347 51.9365 59.271 53.2062 57.9788Z" fill="#23A0EC"/>
        <path d="M52.1888 55.5233C52.0944 55.6169 52.0194 55.7283 51.9682 55.8512C51.917 55.9741 51.8906 56.106 51.8906 56.2392C51.8906 56.3724 51.917 56.5043 51.9682 56.6271C52.0194 56.75 52.0944 56.8615 52.1888 56.9551L53.2089 57.9823C54.2176 56.9167 55.3089 55.9328 56.4724 55.0401L55.2847 53.8339C55.1129 53.6623 54.8851 53.5588 54.6433 53.5423C54.4015 53.5259 54.1619 53.5976 53.9686 53.7444C53.9314 53.7722 53.8956 53.8021 53.8616 53.8339L52.1888 55.5233Z" fill="#23A0EC"/>
        <path opacity="0.43" d="M52.1888 55.5233C52.0944 55.6169 52.0194 55.7283 51.9682 55.8512C51.917 55.9741 51.8906 56.106 51.8906 56.2392C51.8906 56.3724 51.917 56.5043 51.9682 56.6271C52.0194 56.75 52.0944 56.8615 52.1888 56.9551L53.2089 57.9823C54.2176 56.9167 55.3089 55.9328 56.4724 55.0401L55.2847 53.8339C55.1129 53.6623 54.8851 53.5588 54.6433 53.5423C54.4015 53.5259 54.1619 53.5976 53.9686 53.7444C53.9314 53.7722 53.8956 53.8021 53.8616 53.8339L52.1888 55.5233Z" fill="#23A0EC"/>
        <path d="M75.5893 73.6349L57.0428 54.8145C56.9786 54.7465 56.7753 54.836 56.4829 55.0364C55.3193 55.9292 54.2281 56.913 53.2194 57.9787C51.9461 59.2708 50.7513 60.6167 50.3411 61.3039C50.2091 61.5294 50.1556 61.6833 50.2163 61.7442L68.7628 80.5646C69.209 81.0186 69.7407 81.379 70.327 81.6251C70.9132 81.8711 71.5424 81.9978 72.1778 81.9978C72.8133 81.9978 73.4424 81.8711 74.0286 81.6251C74.6149 81.379 75.1466 81.0186 75.5929 80.5646C76.4977 79.6397 77.0043 78.3951 77.0036 77.099C77.003 75.8029 76.4951 74.5588 75.5893 73.6349Z" fill="#23A0EC"/>
      </g>
      <defs>
        <clipPath id="clip0_1919_10253">
          <rect width="77" height="82" fill="white"/>
        </clipPath>
        <clipPath id="clip1_1919_10253">
          <rect width="24.4574" height="24.3311" fill="white" transform="matrix(0.994655 0.103252 -0.104339 0.994542 22.1719 24.9351)"/>
        </clipPath>
      </defs>
    </svg>


  );
};

IconCard.defaultProps = {
  className: null,
  rootClassName: null,
};

IconCard.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconCard;
