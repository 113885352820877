import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from '../../LandingPage.module.css';

const IconCard = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="87"
      height="61"
      fill="none"
      viewBox="0 0 87 61"
    >
      <g clipPath="url(#clip0_1408_7438)">
        <path
          fill="#23A0EC"
          d="M56.07 47.063H30.934v12.656H56.07V47.062z"
        ></path>
        <path
          fill="#23A0EC"
          d="M86.268 0H.732A.731.731 0 000 .73v48.79c0 .403.328.73.732.73h85.536a.73.73 0 00.732-.73V.73a.73.73 0 00-.732-.73z"
        ></path>
        <path fill="#AFCDFB" d="M86.267 3.9H.73v43.362h85.537V3.899z"></path>
        <path
          fill="#23A0EC"
          d="M24.343 57.698H62.66a1.51 1.51 0 011.39.925c.075.181.114.376.114.573V61H22.84v-1.806c0-.397.159-.778.44-1.059.282-.28.665-.438 1.063-.438z"
        ></path>
        <path
          fill="#5F91E6"
          d="M14.421 6.504h58.161a.673.673 0 01.672.669v37.484H13.75V7.173a.668.668 0 01.671-.67z"
        ></path>
        <path fill="#fff" d="M72.554 9.963H14.445v33.856h58.109V9.963z"></path>
        <path
          fill="#23A0EC"
          d="M72.554 37.752H14.445v3.678h58.109v-3.678z"
        ></path>
        <path fill="#DFEBFA" d="M72.554 38.94H14.445v1.301h58.109v-1.3z"></path>
        <path
          fill="#B5453C"
          d="M18.101 38.935h-3.656v1.313h3.656v-1.313z"
        ></path>
        <path
          fill="#EF6279"
          d="M19.88 38.655h-2.102a.457.457 0 00-.458.455v.961c0 .252.205.456.458.456h2.102a.457.457 0 00.458-.456v-.96a.457.457 0 00-.458-.456z"
        ></path>
        <path
          fill="#454545"
          d="M61.483 18.68L44.02 24.535l-17.886-5.883 17.465-5.857 17.886 5.883z"
        ></path>
        <path
          fill="#454545"
          d="M26.672 28.774a.8.8 0 00.443-.806.796.796 0 00-.613-.687v-8.628h-.368v8.628a.802.802 0 00-.613.687.795.795 0 00.443.806.8.8 0 00-.448.716v4.373h1.605v-4.375a.795.795 0 00-.449-.714zM44.02 25.786l-11.09-3.648v5.68c0 .979.39 1.917 1.084 2.608a3.71 3.71 0 002.619 1.08h14.353a3.717 3.717 0 002.618-1.08 3.685 3.685 0 001.085-2.607v-5.611l-10.67 3.578z"
        ></path>
        <g fill="#EF6279" opacity="0.86">
          <path d="M43.502 35.019a8.182 8.182 0 01-5.178-1.845 8.122 8.122 0 01-2.827-4.698 8.1 8.1 0 01.807-5.418 8.15 8.15 0 014.074-3.677 8.193 8.193 0 015.493-.27 8.16 8.16 0 014.417 3.263 8.103 8.103 0 01-1.015 10.264 8.137 8.137 0 01-5.771 2.38zm0-15.333c-1.431 0-2.83.422-4.02 1.214a7.212 7.212 0 00-2.664 3.234 7.178 7.178 0 001.568 7.851 7.261 7.261 0 007.884 1.562 7.227 7.227 0 003.247-2.653 7.184 7.184 0 001.219-4.003 7.198 7.198 0 00-2.121-5.093 7.258 7.258 0 00-5.113-2.112z"></path>
          <path d="M40.55 21.121v11.676l8.603-5.838-8.602-5.838z"></path>
        </g>
        <path fill="#16254A" d="M56.07 50.25H30.934v1.393H56.07V50.25z"></path>
      </g>
      <defs>
        <clipPath id="clip0_1408_7438">
          <path fill="#fff" d="M0 0H87V61H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

IconCard.defaultProps = {
  className: null,
  rootClassName: null,
};

IconCard.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconCard;
