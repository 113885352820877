import React, { useRef, useState } from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import css from './SectionPublishCourse.module.css';

import publishCourseImg from "./publish.svg"
import GoogleAuthButton from "../SectionHero/GoogleAuthButton";
import { Button, NamedLink } from '../../../components';
import classNames from "classnames";
import {stringify} from "../../../util/urlHelpers";
import FacebookAuthButton from "../SectionHero/FacebookAuthButton";

const SectionPublishCourse = ({ location, intl, currentUser, handleGetStarted }) => {

  const [initialEmail, setEmail] = useState(null)
  const inputRef = useRef();

  return (
    <div className={css.root}>
      <div className={css.contentContainer}>
        <div className={css.info}>
          <h2 className={css.title}>
            <FormattedMessage id="SectionPublishCourse.title" />
          </h2>
          <p className={css.subtitle}>
            <FormattedMessage id="SectionPublishCourse.subtitle" />
          </p>

          {!currentUser ? (
            <div className={css.emailContainer}>
              <Button className={css.button} onClick={handleGetStarted}>
                <FormattedMessage id="SectionHero.signUpJoinBtn" />
              </Button>
            </div>
          ) : <>
            <div className={css.createCourseContainer}>
              <NamedLink
                name="NewListingPage"
                className={css.button}
              >
                <FormattedMessage id="SectionHero.createCourseBtn" />
              </NamedLink>
            </div>
          </>}

        </div>
        <div className={css.imageContainer}>
          <img src={publishCourseImg} className={css.image} alt='It’s free to list jobs and search for teachers' />
        </div>

      </div>
    </div>
  )
}

export default SectionPublishCourse;
