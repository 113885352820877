import React from 'react';
import css from './SectionListingsCarousel.module.css';
import AliceCarousel from 'react-alice-carousel';


import '../../../styles/react-alice-carousel.css';
import { FormattedMessage } from "../../../util/reactIntl";
import { ListingCard, NamedLink } from "../../../components";

import { ensureListing } from "../../../util/data";
import { createSlug } from "../../../util/urlHelpers";
import classNames from 'classnames';
import { isJobType } from '../../../util/types';

const SectionListingsCarousel = (props) => {

  const { listings, isCustomCard } = props;
  const items = () => {

    if (!listings) {
      return '';
    }

    return listings.map(l => {
      const currentListing = ensureListing(l);
      const id = currentListing.id.uuid;
      const { title = '', publicData } = currentListing.attributes;
      const slug = `${createSlug(title)}-${publicData.customId}`;
      const isJob = isJobType(currentListing);
      const pageName = isJob ? 'ListingJobPage' : 'ListingCoursePage';

      return (
        <div className={classNames(css.teachersWrapper, {[css.teachersWrapperBlocked]: isCustomCard})}>
          <NamedLink className={css.teachersWrapper} name={pageName} params={{ slug }}>
            <ListingCard listing={l} showAuthorInfo={true} showDescription/>
          </NamedLink>
        </div>
      )
    }
    );
  };


  return <div className={css.teachersCarouselHolder}>
    <h2 className={css.rootTitle}>
      <FormattedMessage id="SectionListings.sectionTitle" />
    </h2>
    <p className={css.rootDescription}>
      <FormattedMessage id="SectionTeachers.teacherCarouselSectionDescription" />
    </p>
    <AliceCarousel
      mouseTrackingEnabled
      items={items()}
      disableDotsControls={true}
      // infinite={true}
      responsive={{
        0: {
          items: 1,
        },
        550: {
          items: 2,
        },
        1024: {
          items: 3,
        }
      }}
    />
  </div>;
};

export default SectionListingsCarousel;
