import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';

import AdminsIcon from "./icons/AdminsIcon"
import { NamedLink, IconCheckMarkAssets } from "../../../components";

import css from './SectionWhyAllCourse.module.css';

const SectionWhyAllCourse = () => {

  return (
    <div className={css.root}>
      <h2 className={css.rootTitle}>
        <FormattedMessage id="SectionWhyAllCourse.title" />
      </h2>
      <p className={css.rootDescription}>
        <FormattedMessage id="SectionWhyAllCourse.description" />
      </p>
      <div className={css.adminInfo}>
        <AdminsIcon className={css.adminInfoIcon} />

        <ul className={css.list}>
          <li className={css.listItem}>
            <div className={css.listItemTitle}>
              <IconCheckMarkAssets />
              <FormattedMessage id="SectionWhyAllCourse.itemTitle1" />
            </div>
            <p className={css.listItemDescription}>
              <FormattedMessage id="SectionWhyAllCourse.itemDescription1" />
            </p>
          </li>
          <li className={css.listItem}>
            <div className={css.listItemTitle}>
              <IconCheckMarkAssets />
              <FormattedMessage id="SectionWhyAllCourse.itemTitle2" />
            </div>
            <p className={css.listItemDescription}>
              <FormattedMessage id="SectionWhyAllCourse.itemDescription2" />
            </p>
          </li>
          <li className={css.listItem}>
            <div className={css.listItemTitle}>
              <IconCheckMarkAssets />
              <FormattedMessage id="SectionWhyAllCourse.itemTitle3" />
            </div>
            <p className={css.listItemDescription}>
              <FormattedMessage id="SectionWhyAllCourse.itemDescription3" />
            </p>
          </li>

        </ul>

        {/* <p className={css.adminInfoDescription}>
          <FormattedMessage
            id="SectionWhyTeach.adminDescription"
            values={{
              searchLink: <NamedLink className={css.underline} name="SearchPage" to={{ search: 'type=teacher' }}  >
                teacher profiles
              </NamedLink>,
            }}
          />
        </p> */}
      </div>
    </div>
  );
};

export default SectionWhyAllCourse;
